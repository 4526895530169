<template>
  <div class="container">
    <div class="select">
      <div class="state">
        状态：
        <el-select v-model="stateValue" clearable placeholder="请选择">
          <el-option
            v-for="item in stateOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="type">
        类型：
        <el-select v-model="typeValue" clearable placeholder="请选择">
          <el-option
            v-for="item in  typeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div>
        发起日期：
        <el-date-picker
          v-model="dateValue"
          type="datetimerange"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </div>
    </div>
    <div class="export">
      <div class="input">
        <div>关键词：</div>
        <el-input v-model="input" placeholder="工单编号\工单标题\关联单号"></el-input>
      </div>
      <el-button type="primary" @click="getWorkOrderList('nopage')">查询</el-button>
    </div>
    <div class="table">
      <el-table  :cell-style="{ textAlign: 'center' }"   @sort-change="sortChange" :header-cell-style="{textAlign: 'center'}" border :data="balanceList" stripe style="width: 100%">
        <el-table-column prop="work_no" label="工单编号" ></el-table-column>
        <el-table-column prop="title" label="工单标题"></el-table-column>
        <el-table-column prop="type" label="工单类型"></el-table-column>
        <el-table-column prop="order_no" label="关联单号"></el-table-column>
        <el-table-column prop="status" label="工单状态"></el-table-column>
        <el-table-column prop="create_time"  sortable label="发起时间"></el-table-column>
        <el-table-column prop="finish_time"  sortable label="完成时间"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button @click="getWorkOrderDetail(scope.row)" type="text" size="small">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        @current-change="curretnChange"
        @prev-click="curretnChange"
        @size-change="handleSizeChange"
        :current-page="page"
        :hide-on-single-page="false"
        :page-sizes="[10, 20, 30, 40]"
        @next-click="curretnChange"
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog  class="dialog" :close-on-press-escape="false" :visible.sync="dialogVisible" width="60%">
      <div class="content">
        <div class="record-title">工单详情</div>
        <div v-if="detailInfo.info">工单编号：{{detailInfo.info.work_no}}</div>
        <div v-if="detailInfo.info">工单类别：{{detailInfo.info.type}}</div>
        <div v-if="detailInfo.info">工单标题：{{detailInfo.info.title}}</div>
        <div v-if="detailInfo.info">发起时间：{{detailInfo.info.create_time}}</div>
        <div v-if="detailInfo.info">工单状态：{{detailInfo.info.status}}</div>
        <div class="record-title" v-if="detailInfo.records !== null">处理记录</div>
        <div class="record-list" >
          <div class="item" v-for="(ele, inx) in detailInfo.records" :key="inx">
            <div class="image">
              <img :src="ele.header_img" alt="">
            </div>
            <div class="describe">
              <div class="flex-c-f">{{ele.username}}：<div class="time">{{ele.dealwith_time}}</div></div>
              <div class="flex-c-f">问题描述：<div class="time">{{ele.description}}</div></div>
              <div >
                <video v-if="ele.videos" class="img" controls :src="ele.videos" ></video>
                <img class="img" :src="ele" alt="" v-for="(ele, inx) in ele.imgs" :key="inx">
              </div>

            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formatDateTime } from '@/utils/util'
export default {
  name: 'workorder',
  data () {
    return {
      dialogVisible: false,
      input: '',
      stateValue: '',
      typeValue: '',
      order_by: '',
      limit: 10,
      dateValue: [],
      balanceList: [],
      page: 1,
      stateOptions: [],
      total: 0,
      detailInfo: {},
      typeOptions: []

    }
  },
  created () {
    this.getWorkOrderList()
    this.getTypeAndStatus()
  },
  computed: {
    shopInfo () {
      return JSON.parse(window.localStorage.getItem('shopInfo')) || {}
    }
  },
  methods: {

    sortChange (e) { // 外层排序方式
      var orderBy = e.prop
      if (e.order === 'descending') {
        this.order_by = orderBy + '__desc'
      } else {
        this.order_by = orderBy + '__asc'
      }
      this.getWorkOrderList('nopage')
    },
    getWorkOrderDetail (e) {
      // eslint-disable-next-line camelcase
      var shop_id = this.shopInfo.shop_id
      // eslint-disable-next-line camelcase
      var work_id = e.work_id
      this.$http.post('/v1/work_order/detail', { shop_id, work_id }).then(res => {
        if (res.data.data !== null) {
          this.detailInfo = res.data.data

          this.dialogVisible = true
        } else {
          this.$message.success('暂时没有处理信息')
        }
      }).catch(err => {
        return this.$message.error(err)
      })
    },
    handleSizeChange (e) {
      this.limit = e
      this.getWorkOrderList()
    },
    getTypeAndStatus () {
      // 获取结算单状态列表
      // eslint-disable-next-line camelcase
      var shop_id = this.shopInfo.shop_id
      this.$http
        .post('/v1/work_order/index', { shop_id })
        .then(res => {
          this.stateOptions = res.data.data.order_status
          this.typeOptions = res.data.data.order_type
        })
        .catch(err => {
          return this.$message.error(err)
        })
    },

    curretnChange (e) {
      // 页码切换
      this.page = e
      this.getWorkOrderList()
    },
    getWorkOrderList (e) {
      // 获取结算单列表
      // eslint-disable-next-line camelcase
      var shop_id = this.shopInfo.shop_id
      var keyword = this.input
      // eslint-disable-next-line camelcase
      var order_status = this.stateValue
      // eslint-disable-next-line camelcase
      var order_by = this.order_by
      // eslint-disable-next-line camelcase
      var order_type = this.typeValue
      this.page = e === 'nopage' ? 1 : this.page
      var page = this.page
      var limit = this.limit
      // eslint-disable-next-line camelcase
      var start_date = ''
      // eslint-disable-next-line camelcase
      var end_date = ''
      if (Object.prototype.toString.call(this.dateValue) === '[object Array]') {
        if (this.dateValue[0]) {
          // eslint-disable-next-line camelcase
          start_date = formatDateTime(this.dateValue[0])
        }
        if (this.dateValue[1]) {
          // eslint-disable-next-line camelcase
          end_date = formatDateTime(this.dateValue[1])
        }
      }
      this.$http
        .post('/v1/work_order/lst', {
          shop_id,
          keyword,
          order_type,
          page,
          limit,
          order_by,
          start_date,
          end_date,
          order_status
        })
        .then(res => {
          this.balanceList = res.data.data.list
          this.total = res.data.data.total
        })
    }
  }
}
</script>

<style scoped lang="less">
.container {
  .select {
    display: flex;
    align-items: center;
    font-size: 18px;
    & > div {
        margin-right: 40px;
    }
    .el-date-editor {
      width: 300px;
    }
  }
  .enlarge {
    height: 400px;
    width: 400px;
  }
  .export {
    margin-top: 20px;
    display: flex;
    align-items: center;

    .input {
      display: flex;
      align-items: center;
      div {
        width: 100px;
        font-size: 18px;
      }
      .el-input {
        width: 300px;
        font-size: 14px;
      }
    }
    .el-button {
      margin-left: 40px;
    }
  }
  .table {
    margin-top: 30px;
    td {
      .cell {
        width: 40px;
        height: 40px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    margin-bottom: 20px;
    align-items: center;
  }
  .dialog {
   /deep/ .el-dialog__header {
      /deep/ .el-dialog__title {
        font-size: 20px;
      }
    }
    .content {
      font-size:18px;
      margin-top: -20px;
      &>div {
        margin-bottom: 10px;
      }
      .record-title {
        font-size: 23px;
        font-weight: 700;
      }
      .record-list {
        .item {
          display: flex;
          margin-bottom: 20px;
          .image {
            width: 100px;
            height: 100px;
            margin-right: 30px;
            img {
              width: 100%;
              height: 100%
            }
          }
          .flex-c-f {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
          }
          .img {
            width: 200px;
            height: 200px;
            margin-right: 20px;
          }
        }
      }
    }
  }
}
</style>
